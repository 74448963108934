import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';
import Model from '../../../utils/Model';

import dayjs from 'dayjs';
import CustomFieldModel from '../../../common/core/custom-fields/CustomFieldModel';
import CommentModel from '../../comments/CommentModel';
import EmailModel from '../../mailboxes/models/EmailModel';
import OrderModel from '../../orders/models/OrderModel';
import AccountModel from './accounts/AccountModel';
import ContactModel from './contacts/ContactModel';
import FieldModel from './fields/FieldModel';
import ManagerModel from './managers/ManagerModel';
import ContractorEntityPassportModel from './passport/ContractorEntityPassportModel';
import ContractorIndividualPassportModel from './passport/ContractorIndividualPassportModel';
import ContractorPhysicalPassportModel from './passport/ContractorPhysicalPassportModel';
import PaymentModel from './payments/PaymentModel';
import TypeModel from './types/TypeModel';

export const CONTRACTOR_LEGAL_PHYSICAL = 'physical';
export const CONTRACTOR_LEGAL_INDIVIDUAL = 'individual';
export const CONTRACTOR_LEGAL_ENTITY = 'entity';

class ContractorModel extends Model {
    static validators = {
        group: composeValidators(validators.required),
    };

    initialize(props) {
        super.initialize(props);
        this.id = props.id;
        this.externalId = props.externalId || null;

        this.syncedAt = (props.syncedAt && dayjs(props.syncedAt)) || null;

        this.isArchived = props.isArchived !== undefined ? !!props.isArchived : null;
        this.name = props.name || '';

        this.legal = props.legal || {};
        this.legal_code = props.legal_code || null;
        //this.legalName = props.legal && props.legal.name ? props.legal.name : null;

        this.group = props.group && props.group.id ? props.group.id : null;
        this.groupName = props.group && props.group.name ? props.group.name : null;

        this.physicalPassport =
            this.legal_code === CONTRACTOR_LEGAL_PHYSICAL
                ? (this.passport = new ContractorPhysicalPassportModel(
                    props.passport,
                    this.legal_code
                ))
                : {};
        this.individualPassport =
            this.legal_code === CONTRACTOR_LEGAL_INDIVIDUAL
                ? (this.passport = new ContractorIndividualPassportModel(
                    props.passport,
                    this.legal_code
                ))
                : {};
        this.entityPassport =
            this.legal_code === CONTRACTOR_LEGAL_ENTITY
                ? (this.passport = new ContractorEntityPassportModel(
                    props.passport,
                    this.legal_code
                ))
                : {};

        this.contacts =
            props.contacts && props.contacts.length
                ? props.contacts.map((item) => new ContactModel(item))
                : [];

        this.comments =
            props.comments && props.comments.length
                ? props.comments.map((item) => new CommentModel(item))
                : [];

        this.accounts =
            props.accounts && props.accounts.length
                ? props.accounts.map((item) => new AccountModel(item))
                : [];

        this.managers =
            props.managers && props.managers.length
                ? props.managers.map((item) => new ManagerModel(item))
                : [];

        this.fields =
            props.fields && props.fields.length
                ? props.fields.map((item) => new FieldModel(item))
                : [];

        this.types =
            props.types && props.types.length ? props.types.map((item) => new TypeModel(item)) : [];

        this.ordersCount = props.ordersCount || 0;
        this.emailsCount = props.emailsCount || 0;
        this.paymentsCount = props.paymentsCount || 0;

        this.orders = [];
        this.ordersFetched = false;

        this.emails = [];
        this.emailsFetched = false;

        this.payments = [];
        this.paymentsFetched = false;

        this.customFields = props.customFields || {};
        this.features = props.features || {};

        const fieldsData = props.fieldsMeta || {};

        this.fieldsMeta = [];
        for (let key in fieldsData) {
            if (fieldsData.hasOwnProperty(key)) {
                this.fieldsMeta.push(new CustomFieldModel(fieldsData[key]));
            }
        }
    }

    getUrl() {
        return `/contractor/${this.id}`;
    }

    setOrders(orders) {
        this.orders = orders.map((o) => new OrderModel(o));
        this.ordersFetched = true;
    }

    setEmails(emails) {
        this.emails = emails.map((e) => new EmailModel(e));
        this.emailsFetched = true;
    }

    setPayments(payments) {
        this.payments = payments.map((p) => new PaymentModel(p));
        this.paymentsFetched = true;
    }

    addComment(props) {
        if (props.parentId) {
            const _insert = (list, id, props) =>
                list.map((item) => {
                    if (item.id === id) item.replies.unshift(new CommentModel(props));
                    else if (item.replies && item.replies.length)
                        item.replies = _insert(item.replies, id, props);

                    return item;
                });
            this.comments = _insert(this.comments, props.parentId, props);
        } else {
            this.comments.unshift(new CommentModel(props));
        }
    }

    deleteComment(id) {
        const _delete = (list, id) =>
            list.filter((item) => {
                if (item.replies && item.replies.length) item.replies = _delete(item.replies, id);
                return item.id !== id;
            });
        this.comments = _delete(this.comments, id);
    }

    replaceComment(id, props) {
        const _replace = (list, id, props) =>
            list.map((item) => {
                if (item.id === id) return new CommentModel(props);
                if (item.replies && item.replies.length)
                    item.replies = _replace(item.replies, id, props);
                return item;
            });
        this.comments = _replace(this.comments, id, props);

        console.log(this.comments);
    }
}

export default ContractorModel;
