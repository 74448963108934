export default {
    download: {
        ru: 'Скачать',
        en: 'Download',
    },
    add: {
        ru: 'Добавить',
        en: 'Add',
    },
    close: {
        en: 'Close',
        ru: 'Закрыть',
    },
    adding: {
        ru: 'Добавление...',
        en: 'Adding...',
    },
    edit: {
        ru: 'Редактировать',
        en: 'Edit',
    },
    cancel: {
        ru: 'Отмена',
        en: 'Cancel',
    },
    clear: {
        ru: 'Очистить',
        en: 'Clear',
    },
    ok: {
        ru: 'Ок',
        en: 'Ok',
    },
    yes: {
        ru: 'Да',
        en: 'Yes',
    },
    no: {
        ru: 'Нет',
        en: 'Да',
    },
    save: {
        ru: 'Сохранить',
        en: 'Save',
    },
    saving: {
        ru: 'Сохранение...',
        en: 'Saving...',
    },
    create: {
        ru: 'Создать',
        en: 'Create',
    },
    creating: {
        ru: 'Создание...',
        en: 'Creating...',
    },
    logout: {
        ru: 'Выйти',
        en: 'Logout',
    },
    activate: {
        ru: 'Активировать',
        en: 'Activate',
    },
    activating: {
        ru: 'Активация...',
        en: 'Activating...',
    },
    deactivate: {
        ru: 'Отключить',
        en: 'Deactivate',
    },
    deactivating: {
        ru: 'Отключени...',
        en: 'Deactivating...',
    },
    delete: {
        ru: 'Удалить',
        en: 'Delete',
    },
    deleting: {
        ru: 'Удаление...',
        en: 'Deleting...',
    },
    configure: {
        ru: 'Конфигурация',
        en: 'Configure',
    },
    finish: {
        ru: 'Завершить',
        en: 'Finish',
    },
    goToList: {
        ru: 'К списку',
        en: 'To list',
    },
    reply: {
        ru: 'Ответить',
        en: 'Reply',
    },
    view: {
        ru: 'Просмотр',
        en: 'View',
    },
    refreshData: {
        ru: 'Обновить данные',
        en: 'Refresh data',
    },
    detach: {
        ru: 'Отвязать',
        en: 'Detach',
    },
    attach: {
        ru: 'Привязать',
        en: 'Attach',
    },
};
