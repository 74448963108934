import classNames from 'classnames';
import { FC, PropsWithChildren, useEffect, useState } from 'react';

interface ModalProps extends PropsWithChildren {
    shown: boolean;
    small?: boolean;
    large?: boolean;
    onClose?: () => void;
}

interface ModalType {
    Header: FC<ModalHeaderProps>;
    Body: FC<PropsWithChildren>;
    Footer: FC<PropsWithChildren>;
}

export const Modal: FC<ModalProps> & ModalType = ({
    shown,
    small = false,
    large = false,
    onClose,
    children,
}) => {
    const [hiding, setHiding] = useState(false);
    const [shouldRender, setShouldRender] = useState(shown);

    useEffect(() => {
        if (shown) {
            setShouldRender(true);
            setHiding(false);
        } else {
            setTimeout(() => {
                setHiding(false);
                setShouldRender(false);
            }, 500);
            setHiding(true);
        }
    }, [shown]);

    if (!shouldRender) {
        document.body.classList.remove('modal-open');
        return null;
    }
    document.body.classList.add('modal-open');

    const cls = classNames('modal-dialog', { 'modal-lg': large }, { 'modal-sm': small });

    return (
        <div className="dpws-modal">
            <div className={'modal-backdrop animated ' + (hiding ? 'fadeOut' : 'fadeIn')} />
            <div className="modal">
                <div className={cls}>
                    <div
                        className={
                            'modal-content animated ' + (hiding ? 'slideOutUp' : 'slideInDown')
                        }
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ModalHeaderProps extends PropsWithChildren {
    onClose?: () => void;
}

const Header: FC<ModalHeaderProps> = ({ children, onClose }: ModalHeaderProps) => {
    return (
        <div className="modal-header">
            <h5 className="modal-title">{children}</h5>
            {onClose && (
                <button type="button" className="close" onClick={onClose}>
                    <span>×</span>
                </button>
            )}
        </div>
    );
};

Modal.Header = Header;

Modal.Body = (props: PropsWithChildren) => {
    return <div className="modal-body">{props.children}</div>;
};

Modal.Footer = (props: PropsWithChildren) => {
    return <div className="modal-footer">{props.children}</div>;
};
export default Modal;
