import _ from 'lodash';
import OrderClaimModel from 'modules/orders/models/claims/OrderClaimModel';
import OrderProjectModel from 'modules/orders/models/project/OrderProjectModel';
import { t } from 'translations';
import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';
import Model from '../../../utils/Model';

import dayjs from 'dayjs';
import StructureNodeModel from 'modules/structure/models/StructureNodeModel';
import CustomFieldModel from '../../../common/core/custom-fields/CustomFieldModel';
import CommentModel from '../../comments/CommentModel';
import ContractorModel from '../../contractors/models/ContractorModel';
import CompanyModel from '../../structure/models/CompanyModel';
import DepartmentModel from '../../structure/models/DepartmentModel';
import WarehouseModel from '../../structure/models/WarehouseModel';
import CustomDocumentModel from './files/CustomDocumentModel';
import CustomFileModel from './files/CustomFileModel';
import KitFileModel from './files/KitFileModel';
import OrderItemModel from './items/OrderItemModel';
import ManagerModel from './managers/ManagerModel';
import OrderPaymentModel from './payments/OrderPaymentModel';
import OrderAvailableStatusModel from './status/OrderAvailableStatusModel';
import OrderStatusChangeModel from './status/OrderStatusChangeModel';
import OrderStatusModel from './status/OrderStatusModel';

class OrderModel extends Model {
    static validators = {
        companyId: composeValidators(validators.required),
        departmentId: composeValidators(validators.required),
        warehouseId: composeValidators(validators.required),
        date: composeValidators(validators.required, validators.date),
        plannedShipmentDate: composeValidators(validators.date),
    };

    id;

    initialize(props) {
        super.initialize(props);
        this.id = props.id;
        this.externalId = props.externalId || null;

        this.syncedAt = (props.syncedAt && dayjs(props.syncedAt)) || null;

        this.isLocked = props.isLocked || false;
        this.isDraft = props.isDraft || false;

        this.number = props.number || t('orders.withoutNumber');
        this.isNumberAssigned = !!props.number;

        this.comment = props.comment || '';
        this.totalValue = props.totalValue || '';
        this.totalValueWithoutDiscount = props.totalValueWithoutDiscount || '';
        this.paidValue = props.paidValue || '';
        this.currency = props.currency || '';
        this.extra = props.extra || {};

        this.project = new OrderProjectModel(props.project || {});

        this.parentOrder = props.parentOrder ? new OrderModel(props.parentOrder) : null;
        this.nestedOrders = props.nestedOrders?.map((o) => new OrderModel(o)) ?? [];

        this.company =
            props.company && props.company.id
                ? new CompanyModel(props.company)
                : new CompanyModel({});
        this.department =
            props.department && props.department.id
                ? new DepartmentModel(props.department)
                : new DepartmentModel({});
        this.warehouse =
            props.warehouse && props.warehouse.id
                ? new WarehouseModel(props.warehouse)
                : new WarehouseModel({});
        this.region = props.region ?? {};
        this.structureNode =
            props.structureNode && props.structureNode.id
                ? new StructureNodeModel(props.structureNode)
                : new StructureNodeModel({});

        this.paidAt = (props.paidAt && dayjs(props.paidAt)) || null;
        this.processedAt = (props.processedAt && dayjs(props.processedAt)) || null;

        this.contractor =
            props.contractor && props.contractor.id
                ? new ContractorModel(props.contractor)
                : new ContractorModel();

        this.status =
            props.status && props.status.id
                ? new OrderStatusModel(props.status)
                : new OrderStatusModel();

        this.comments =
            props.comments && props.comments.length
                ? props.comments.map((item) => new CommentModel(item))
                : [];

        this.managers =
            props.managers && props.managers.length
                ? props.managers.map((item) => new ManagerModel(item))
                : [];

        this.actualKitFile = new KitFileModel(props.actualKitFile || { orderId: this.id });

        this.kitFiles =
            props.kitFiles && props.kitFiles.length
                ? props.kitFiles.map((item) => new KitFileModel(item))
                : [];

        this.availableStatuses =
            props.availableStatuses && props.availableStatuses.length
                ? props.availableStatuses.map((item) => new OrderAvailableStatusModel(item))
                : [];

        this.statusChanges = [];

        this.items =
            props.items && props.items.length
                ? props.items.map((item) => new OrderItemModel(item))
                : [];
        this.setTasks(props.tasks);
        this.setCustomFiles(props.customFiles);
        this.setClaims(props.claims);
        this.customDocuments =
            props.customDocuments && props.customDocuments.length
                ? props.customDocuments.map((f) => new CustomDocumentModel(f))
                : [];

        this.payments =
            props.orderPayments && props.orderPayments.length
                ? props.orderPayments.map((p) => new OrderPaymentModel(p))
                : [];

        this.productsCostFormatted = props.productsCostFormatted || '';
        this.servicesCostFormatted = props.servicesCostFormatted || '';

        this.itemsCount = props.itemsCount || 0;
        this.partsCount = props.partsCount || 0;
        this.activeClaimsCount = props.activeClaimsCount || 0;
        this.paymentsCount = props.paymentsCount || 0;
        this.documentsCount = props.documentsCount || 0;
        this.customFields = props.customFields || {};
        this.type = props.type || '';
        this.typeName = props.typeName || '';

        const fieldsData = props.fieldsMeta || {};

        this.fieldsMeta = [];
        for (let key in fieldsData) {
            if (fieldsData.hasOwnProperty(key)) {
                this.fieldsMeta.push(new CustomFieldModel(fieldsData[key]));
            }
        }
    }

    setTasks(tasks) {
        this.tasks = [];
        this.tasksCount = 0;
        if (!Array.isArray(tasks)) return;

        this.tasks = tasks;
        this.tasksCount = this.tasks.length;
    }

    setClaims(claims) {
        this.claims = [];
        this.activeClaimsCount = 0;
        if (!Array.isArray(claims)) return;

        this.claims = claims.map((c) =>
            c instanceof OrderClaimModel ? c : new OrderClaimModel(c)
        );
        this.activeClaimsCount =
            this.claims.reduce((count, v) => {
                if (!v.isClosed) {
                    return count + 1;
                }
            }, 0) || 0;
    }

    setCustomFiles(files) {
        this.customFiles = [];
        if (!Array.isArray(files)) return;
        this.customFiles = files.map((f) =>
            f instanceof CustomFileModel ? f : new CustomFileModel(f)
        );
    }

    addCustomFile(file, prepend = false) {
        if (prepend) {
            this.customFiles.unshift(new CustomFileModel(file));
        } else {
            this.customFiles.push(new CustomFileModel(file));
        }
    }

    setCustomDocuments(documents) {
        this.customDocuments = documents.map((d) => new CustomDocumentModel(d));
    }

    getUrl(section = null) {
        return `/order/${this.id}` + (section ? `/${section}` : '');
    }

    getDate() {
        if (this.customFields['date']) {
            const date = dayjs(this.customFields['date']);
            if (date.isValid()) {
                return date.format('DD.MM.YYYY');
            } else {
                return this.customFields['date'];
            }
        }
        return '';
    }

    getPaidDate() {
        return this.paidAt ? this.paidAt.format('DD.MM.YYYY') : '';
    }

    getProcessedDate() {
        return this.processedAt ? this.processedAt.format('DD.MM.YYYY') : '';
    }

    getPlannedShipmentDate() {
        return this.plannedShipmentDate ? this.plannedShipmentDate.format('DD.MM.YYYY') : '';
    }

    hasKitFile() {
        return this.actualKitFile.id != null;
    }

    setStatusChanges(statusChanges) {
        this.statusChanges = statusChanges.map((sc) => new OrderStatusChangeModel(sc));
    }

    setItems(items) {
        this.items = items.map((i) => new OrderItemModel(i));
    }

    setPayments(payments) {
        this.payments = payments.map((p) => new OrderPaymentModel(p));
    }

    addPayment(payment) {
        this.payments.unshift(new OrderPaymentModel(payment));
    }

    getItems() {
        return _.filter(this.items, (i) => !i.isPart);
    }

    getParts() {
        return _.filter(this.items, (i) => i.isPart);
    }

    addComment(props) {
        if (props.parentId) {
            const _insert = (list, id, props) =>
                list.map((item) => {
                    if (item.id === id) item.replies.unshift(new CommentModel(props));
                    else if (item.replies && item.replies.length)
                        item.replies = _insert(item.replies, id, props);

                    return item;
                });
            this.comments = _insert(this.comments, props.parentId, props);
        } else {
            this.comments.unshift(new CommentModel(props));
        }
    }

    deleteComment(id) {
        const _delete = (list, id) =>
            list.filter((item) => {
                if (item.replies && item.replies.length) item.replies = _delete(item.replies, id);
                return item.id !== id;
            });
        this.comments = _delete(this.comments, id);
    }

    replaceComment(id, props) {
        const _replace = (list, id, props) =>
            list.map((item) => {
                if (item.id === id) return new CommentModel(props);
                if (item.replies && item.replies.length)
                    item.replies = _replace(item.replies, id, props);
                return item;
            });
        this.comments = _replace(this.comments, id, props);
    }
}

export default OrderModel;
